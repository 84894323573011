import CategoriesPage from "./pages/CategoriesPage";
import AllNewsPage from "./pages/AllNewsPage";
import "./assets/css/style.css";
import Header from "./components/Header";
import PostPage from "./pages/PostPage";
import CategoryPage from "./pages/CategoryPage";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Header />
        <Routes>
          <Route path="/" element={<AllNewsPage />} />
          <Route path="/post/:id" element={<PostPage />} />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="/category/posts/:id" element={<CategoryPage />} />
         
        </Routes>
    </>
  );
}

export default App;