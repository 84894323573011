import categoryIcon from "../assets/images/menu-btn.svg";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header className="header">
      <div className="container">
        <Link to="/categories" className="btn-category">
          <img src={categoryIcon} alt="Меню" />
        </Link>
      </div>
    </header>
  );
}

export default Header;