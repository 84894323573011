import PostList from "../components/PostList";

function AllNewsPage() {
  return (
    <section className="mobile-block">
      <div className="mobile-block__header">
        Все новости
      </div>
      <PostList />
    </section>
  );
}

export default AllNewsPage;