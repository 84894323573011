import { useEffect, useState } from "react";
import AllNewsIcon from "../assets/images/categories/all_news_image.svg";
import { Link } from "react-router-dom";
import axios from "axios";

function CategoriesPage() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await axios.get(`https://77c0d899b285b07d.mokky.dev/categories`);
        setCategories(response.data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchCategories();
  }, []); 

  return (
    <section className="mobile-block">
      <div className="mobile-block__header is-red">Категории</div>
      <div className="container">
        <div className="category-row">
          <Link to="/" className="category-item">
            <img src={AllNewsIcon} className="category-item__img" alt="Все новости" />
            <span className="category-item-title">Все новости</span>
          </Link>
          {categories.map((category) => (
            <Link to={`/category/posts/${category.id}`} className="category-item" key={category.id}>
              <img src={category.imageURL} alt={category.name} className="category-item__img" />
              <span className="category-item-title">{category.name}</span> {/* Fixed typo */}
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default CategoriesPage;
