import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import PostCard from "../components/PostCard";

function CategoryPage(){
    const [category,setCategory] = useState({});
    const {id} = useParams();
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        async function fetchCategory() {
            try{
                const response = await axios.get(`https://77c0d899b285b07d.mokky.dev/categories/${id}`);
                setCategory(response.data)
            } catch(e){
                console.log(e)

            }

            
        }



        async function fetchPosts() {
          try {
            const response = await axios.get('https://77c0d899b285b07d.mokky.dev/post');
            setPosts(response.data);
          } catch (error) {
            console.log(error);
          }
        }
        fetchCategory();
        fetchPosts();
      }, [id]);


    return(
        <section class="mobile-block">
        <div class="mobile-block__header is-green">
            {category.name}
        </div>
      <div class="all-news-block">
       <div className="all-news-block">
           {posts.map((post) => {
            return post.category === category.name?(
                <PostCard key={post.id} post={post}/>
            ) : null
           
           })}
        </div>
      </div>
    </section>
    );
}
export default CategoryPage;