import BackIcon from "../assets/images/Frame.svg";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

function PostPage() {
  const { id } = useParams();
  const [post, setPost] = useState({});

  useEffect(() => {
    async function fetchPost() {
      try {
        const response = await axios.get(`https://77c0d899b285b07d.mokky.dev/post/${id}`);
        setPost(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPost();
  }, [id]);

  return (
    <section className="mobile-block">
      <div className="container_btn">
        <div className="back-button">
          <Link to="/">
            <img src={BackIcon} alt="Назад" /> Назад
          </Link>
        </div>
      </div>

      <div className="container">
        <div className="post-detail-block">
          <h3 className="news-card__title">{post.title}</h3>
          <span className="news-card__date">{post.date}</span>
          <p className="description">{post.descryption}</p>
          <img src={post.image_URL} alt="Пост" />
          <span className="author">
            Источник: <strong className="light-green-text">{post.author}</strong>
          </span>
          <button className="tag-button">{post.category}</button>
        </div>
      </div>
    </section>
  );
}

export default PostPage;